import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Newsletter from '../components/newsletter'
import Contattami from '../components/contattami'
import {FormattedMessage} from 'react-intl'
import {filterByLang} from '../utility/utils'

const ChiSono = ({ pageContext: { locale } }) => (
  <StaticQuery
    query={graphql`
      query {
        topimg: file(relativePath: { eq: "testate/chi_sono_header.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        intro: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "20bivaH1Knoat9CQUnmz26"}}) {
          edges {
            node {
              node_locale
              claim {
                childMarkdownRemark {
                  html
                }
              }
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        } 
        q1: allContentfulContenutoGenerico(filter: {contentful_id: {eq: "7zRgIiSlPbDRzuFUkIWMcp"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        } 
        q2:  allContentfulContenutoGenerico(filter: {contentful_id: {eq: "A9afMDYNLMnvs5hvav5EO"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
              immagine {
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const art1 = filterByLang(data.q1,locale)[0]; 
      const art2 = filterByLang(data.q2,locale)[0]; 
      const intro = filterByLang(data.intro,locale)[0];
      return (
        <Layout locale={locale}>
          <SEO title="chiSono" />
          <div className="row">
            <div className="col-12 text-center">
              <FormattedMessage id="chiSono">
                  {txt => <h1 className="pagetitle">{txt}</h1>}
              </FormattedMessage>
            </div>
          </div>
     
          <div className="section section--top">
            <div className="row">
              <div className="col-12">
                <Img className="section__topimg" fluid={data.topimg.childImageSharp.fluid} />
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col-12 ">
                {intro.claim &&
                  <div className="section__incipit"  dangerouslySetInnerHTML={{__html: intro.claim.childMarkdownRemark.html}} />
                }
                <div className="section__text"  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
              </div>
            </div>
          </div>

          <div className="section">
            
            <div className="row">
              <div className="col-12 text-center">
                <FormattedMessage id="qualcosaDiMe">
                    {txt => <div className="section__title">{txt}</div>}
                </FormattedMessage>
              </div>
            </div>

            <div className="section__row">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-4">
                  <Img fluid={art1.immagine.fluid}/>
                </div>
                <div className="col-md-6 col-lg-8">
                  <div className="section__text"  dangerouslySetInnerHTML={{__html: art1.testo.childMarkdownRemark.html}} />
                </div>
              </div>
            </div>

            <div className="section__row">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-8  order-md-1 order-12">  
                  <div  className="section__text" dangerouslySetInnerHTML={{__html: art2.testo.childMarkdownRemark.html}} />
                </div>
                <div className="col-md-6 col-lg-4 order-md-12">
                  <Img fluid={art2.immagine.fluid}/>
                </div>
              </div>
            </div>

          </div>

          <Contattami action="contattami"/>

        
          <Newsletter/>
        </Layout>
      )
    }
  }
  />
 
)

export default ChiSono
